function calendarData() {
  const dateArrays = {
    naija_months: {},
    naija_weekdays: {},
    igbo_chars: "",
    igbo_months: {},
    naija_days: {},
    pos_market_days: {
      english: [false, false, false, false],
      igbo: ["Eke", "Ọrie", "Afọr", "Nkwọ"],
      yoruba: ["Ogun", "Jakuta", "Ose", "Awo"],
      hausa: [false, false, false, false, false],
    },
    neg_market_days: {
      english: [false, false, false, false, false],
      igbo: ["Eke", "Nkwọ", "Afọr", "Ọrie", "Eke"],
      yoruba: ["Ogun", "Awo", "Ose", "Jakuta", "Ogun"],
      hausa: [false, false, false, false, false],
    },
    ref_eke_day: {
      english: "6/20/2020",
      igbo: "6/20/2020",
      yoruba: "6/20/2020",
      hausa: "6/20/2020",
    },
  };

  dateArrays.naija_months = {
    english: [
      { month: "January", count: "1", label: "Month" },
      { month: "February", count: "2", label: "Month" },
      { month: "March", count: "3", label: "Month" },
      { month: "April", count: "4", label: "Month" },
      { month: "May", count: "5", label: "Month" },
      { month: "June", count: "6", label: "Month" },
      { month: "July", count: "7", label: "Month" },
      { month: "August", count: "8", label: "Month" },
      { month: "September", count: "9", label: "Month" },
      { month: "October", count: "10", label: "Month" },
      { month: "November", count: "11", label: "Month" },
      { month: "December", count: "12", label: "Month" },
    ],
    igbo: [
      { month: "Jenuarị", count: "Mbụ", label: "Ọnwa" },
      { month: "Febuarị", count: "Abụọ", label: "Ọnwa" },
      { month: "Maachị", count: "Atọ", label: "Ọnwa" },
      { month: "Eprel", count: "Anọ", label: "Ọnwa" },
      { month: "Mee", count: "Ise", label: "Ọnwa" },
      { month: "Juun", count: "Isii", label: "Ọnwa" },
      { month: "Julaị", count: "Asaa", label: "Ọnwa" },
      { month: "Ọgọọst", count: "Asatọ", label: "Ọnwa" },
      { month: "Septemba", count: "Iteghete", label: "Ọnwa" },
      { month: "Ọktoba", count: "Iri", label: "Ọnwa" },
      { month: "Nọvemba", count: "Iri na Otu", label: "Ọnwa" },
      { month: "Disemba", count: "Iri na Abụọ", label: "Ọnwa" },
    ],
    yoruba: [
      { month: "Oṣù Ṣẹ́rẹ́", count: "Mbụ", label: "Osù" },
      { month: "Oṣù Èrèlè", count: "Abụọ", label: "Osù" },
      { month: "Oṣù Ẹrẹ̀nà", count: "Atọ", label: "Osù" },
      { month: "Oṣù Ìgbé", count: "Anọ", label: "Osù" },
      { month: "Oṣù Ẹ̀bibi", count: "Ise", label: "Osù" },
      { month: "Oṣù Òkúdu", count: "Isii", label: "Osù" },
      { month: "Oṣù Agẹmọ", count: "Asaa", label: "Osù" },
      { month: "Oṣù Ògún", count: "Asatọ", label: "Osù" },
      { month: "Oṣù Owewe", count: "Iteghete", label: "Osù" },
      { month: "Oṣù Ọ̀wàrà", count: "Iri", label: "Osù" },
      { month: "Oṣù Bélú", count: "Iri na Otu", label: "Osù" },
      { month: "Oṣù Ọ̀pẹ̀", count: "Iri na Abụọ", label: "Osù" },
    ],
    hausa: [
      { month: "Janairu", count: "Daya", label: "Wata" },
      { month: "Faburairu", count: "Biyu", label: "Wata" },
      { month: "Maris", count: "Uku", label: "Wata" },
      { month: "Afirilu", count: "Hudu", label: "Wata" },
      { month: "Mayu", count: "Biyar", label: "Wata" },
      { month: "Yuni", count: "Shida", label: "Wata" },
      { month: "Yuli", count: "Bakwai", label: "Wata" },
      { month: "Agusta", count: "Takwas", label: "Wata" },
      { month: "Satumba", count: "Tara", label: "Wata" },
      { month: "Oktoba", count: "Goma", label: "Wata" },
      { month: "Nuwamba", count: "Goma Sha Daya", label: "Wata" },
      { month: "Disamba", count: "Goma Sha Biyu", label: "Wata" },
    ],
  };

  dateArrays.naija_weekdays = {
    english: [
      { day: "Sunday", short: "Sun", label: "Day" },
      { day: "Monday", short: "Mon", label: "Day" },
      { day: "Tuesday", short: "Tue", label: "Day" },
      { day: "Wednesday", short: "Wed", label: "Day" },
      { day: "Thursday", short: "Thu", label: "Day" },
      { day: "Friday", short: "Fri", label: "Day" },
      { day: "Saturday", short: "Sat", label: "Day" },
    ],
    igbo: [
      { day: "Ụka", short: "Ụka", label: "Ụbochị" },
      { day: "Mọndee", short: "Mọn", label: "Ụbochị" },
      { day: "Tuzdee", short: "Tuz", label: "Ụbochị" },
      { day: "Wenezdee", short: "Wen", label: "Ụbochị" },
      { day: "Tọọzdee", short: "Tọọ", label: "Ụbochị" },
      { day: "Fraịdee", short: "Fra", label: "Ụbochị" },
      { day: "Satọdee", short: "Sat", label: "Ụbochị" },
    ],
    yoruba: [
      { day: "Àìkú", short: "Àìk", label: "Ọjọ́" },
      { day: "Ajé", short: "Ajé", label: "Ọjọ́" },
      { day: "Ìsẹ́gun", short: "Ìsẹ́", label: "Ọjọ́" },
      { day: "Ọjọ́rú", short: "Ọjọ́r", label: "Ọjọ́" },
      { day: "Ọjọ́bọ", short: "Ọjọ́", label: "Ọjọ́" },
      { day: "Ẹtì", short: "Ẹtì", label: "Ọjọ́" },
      { day: "Àbámẹ́ta", short: "Àbá", label: "Ọjọ́" },
    ],
    hausa: [
      { day: "Lahadi", short: "Lah", label: "Rana" },
      { day: "Litinin", short: "Lit", label: "Rana" },
      { day: "Talata", short: "Tal", label: "Rana" },
      { day: "Laraba", short: "Lar", label: "Rana" },
      { day: "Alhamis", short: "Alh", label: "Rana" },
      { day: "Jummaʼa", short: "Jum", label: "Rana" },
      { day: "Asabar", short: "Asa", label: "Rana" },
    ],
  };

  dateArrays.igbo_chars = `abchdeẹfgwiịjkplmnṅyoọrs
tuụvzABCHDEẸFGWIỊJKPLMNṄYOỌRSTUỤVZ`;

  dateArrays.igbo_months = [
    { month: "Januarị", count: "Mbụ", label: "Ọnwa" },
    { month: "Febuarị", count: "Abụọ", label: "Ọnwa" },
    { month: "Maachị", count: "Atọ", label: "Ọnwa" },
    { month: "Eprel", count: "Anọ", label: "Ọnwa" },
    { month: "May", count: "Ise", label: "Ọnwa" },
    { month: "Juun", count: "Isii", label: "Ọnwa" },
    { month: "Julaị", count: "Asaa", label: "Ọnwa" },
    { month: "Ọgọst", count: "Asatọ", label: "Ọnwa" },
    { month: "Septemba", count: "Iteghete", label: "Ọnwa" },
    { month: "Ọktoba", count: "Iri", label: "Ọnwa" },
    { month: "Nọvemba", count: "Iri na Otu", label: "Ọnwa" },
    { month: "Disemba", count: "Iri na Abụọ", label: "Ọnwa" },
  ];

  dateArrays.naija_days = {
    igbo: [
      { id: 0, day: "Efu", label: "Mkpuru Ụbọchi" },
      { id: 1, day: "Otu", label: "Mkpuru Ụbọchi" },
      { id: 2, day: "Abụọ", label: "Mkpuru Ụbọchi" },
      { id: 3, day: "Atọ", label: "Mkpuru Ụbọchi" },
      { id: 4, day: "Anọ", label: "Mkpuru Ụbọchi" },
      { id: 5, day: "Ise", label: "Mkpuru Ụbọchi" },
      { id: 6, day: "Isii", label: "Mkpuru Ụbọchi" },
      { id: 7, day: "Asaa", label: "Mkpuru Ụbọchi" },
      { id: 8, day: "Asatọ", label: "Mkpuru Ụbọchi" },
      { id: 9, day: "Iteghete", label: "Mkpuru Ụbọchi" },
      { id: 10, day: "Iri", label: "Mkpuru Ụbọchi" },
      { id: 11, day: "Iri na Otu", label: "Mkpuru Ụbọchi" },
      { id: 12, day: "Iri na Abụọ", label: "Mkpuru Ụbọchi" },
      { id: 13, day: "Iri na Atọ", label: "Mkpuru Ụbọchi" },
      { id: 14, day: "Iri na Anọ", label: "Mkpuru Ụbọchi" },
      { id: 15, day: "Iri na Ise", label: "Mkpuru Ụbọchi" },
      { id: 16, day: "Iri na Isii", label: "Mkpuru Ụbọchi" },
      { id: 17, day: "Iri na Asaa", label: "Mkpuru Ụbọchi" },
      { id: 18, day: "Iri na Asatọ", label: "Mkpuru Ụbọchi" },
      { id: 19, day: "Iri na Iteghete", label: "Mkpuru Ụbọchi" },
      { id: 20, day: "Iri Abụọ", label: "Mkpuru Ụbọchi" },
      { id: 21, day: "Iri Abụọ na Otu", label: "Mkpuru Ụbọchi" },
      { id: 22, day: "Iri Abụọ na Abụọ", label: "Mkpuru Ụbọchi" },
      { id: 23, day: "Iri Abụọ na Atọ", label: "Mkpuru Ụbọchi" },
      { id: 24, day: "Iri Abụọ na Anọ", label: "Mkpuru Ụbọchi" },
      { id: 25, day: "Iri Abụọ na Ise", label: "Mkpuru Ụbọchi" },
      { id: 26, day: "Iri Abụọ na Isii", label: "Mkpuru Ụbọchi" },
      { id: 27, day: "Iri Abụọ na Asaa", label: "Mkpuru Ụbọchi" },
      { id: 28, day: "Iri Abụọ na Asatọ", label: "Mkpuru Ụbọchi" },
      { id: 29, day: "Iri Abụọ na Iteghete", label: "Mkpuru Ụbọchi" },
      { id: 30, day: "Iri Atọ", label: "Mkpuru Ụbọchi" },
      { id: 31, day: "Iri Atọ na Otu", label: "Mkpuru Ụbọchi" },
      { id: 32, day: "" },
      { id: 33, day: "" },
      { id: 34, day: "" },
      { id: 35, day: "" },
      { id: 36, day: "" },
      { id: 37, day: "" },
      { id: 38, day: "" },
      { id: 39, day: "" },
      { id: 40, day: "" },
      { id: 41, day: "" },
      { id: 42, day: "" },
    ],
    hausa: [
      { id: 0, day: "sifili" },
      { id: 1, day: "Daya" },
      { id: 2, day: "Biyu" },
      { id: 3, day: "Uku" },
      { id: 4, day: "Hudu" },
      { id: 5, day: "Biyar" },
      { id: 6, day: "Shida" },
      { id: 7, day: "Bakwai" },
      { id: 8, day: "Takwas" },
      { id: 9, day: "Tara" },
      { id: 10, day: "Goma" },
      { id: 11, day: "Goma sha Daya" },
      { id: 12, day: "Goma sha Biyu" },
      { id: 13, day: "Goma sha Uku" },
      { id: 14, day: "Goma sha Hudu" },
      { id: 15, day: "Goma sha Biyar" },
      { id: 16, day: "Goma sha Shida" },
      { id: 17, day: "Goma sha Bakwai" },
      { id: 18, day: "Goma sha Takwas" },
      { id: 19, day: "Goma sha Tara" },
      { id: 20, day: "Ashirin " },
      { id: 21, day: "Ashirin da Daya" },
      { id: 22, day: "Ashirin da Biyu" },
      { id: 23, day: "Ashirin da Uku" },
      { id: 24, day: "Ashirin da Hudu" },
      { id: 25, day: "Ashirin da Biyar" },
      { id: 26, day: "Ashirin da Shida" },
      { id: 27, day: "Ashirin da Bakwai" },
      { id: 28, day: "Ashirin da Takwas" },
      { id: 29, day: "Ashirin da Tara" },
      { id: 30, day: "Talatin" },
      { id: 31, day: "Talatin da Daya" },
      { id: 32, day: "" },
      { id: 33, day: "" },
      { id: 34, day: "" },
      { id: 35, day: "" },
      { id: 36, day: "" },
      { id: 37, day: "" },
      { id: 38, day: "" },
      { id: 39, day: "" },
      { id: 40, day: "" },
      { id: 41, day: "" },
      { id: 42, day: "" },
    ],
    yoruba: [
      { id: 0, day: "sifili" },
      { id: 1, day: "Moókàn" },
      { id: 2, day: "Meéjì" },
      { id: 3, day: "Mẹẹ́ta" },
      { id: 4, day: "Mẹẹ́rin" },
      { id: 5, day: "Maárùn" },
      { id: 6, day: "Mẹẹ́fà" },
      { id: 7, day: "Meéje" },
      { id: 8, day: "Mẹẹ́jọ" },
      { id: 9, day: "Mẹẹ́sàn" },
      { id: 10, day: "mẹẹ́wàá" },
      { id: 11, day: "moókànlá" },
      { id: 12, day: "meéjìlá" },
      { id: 13, day: "mẹẹ́talá" },
      { id: 14, day: "mẹẹ́rìnlá" },
      { id: 15, day: "mẹ́ẹdógún" },
      { id: 16, day: "mẹẹ́rìndílógún" },
      { id: 17, day: "meétàdílógún" },
      { id: 18, day: "Meéjìdílógún" },
      { id: 19, day: "Moókàndílógún" },
      { id: 20, day: "ogún" },
      { id: 21, day: "mokànlelógún" },
      { id: 22, day: "mejilelogun" },
      { id: 23, day: "metalelogun" },
      { id: 24, day: "merinlelogun" },
      { id: 25, day: "medogbon" },
      { id: 26, day: "merindilogbon" },
      { id: 27, day: "metadilogbon" },
      { id: 28, day: "mejidilogbon" },
      { id: 29, day: "mokandilogbon" },
      { id: 30, day: "ogbon" },
      { id: 31, day: "mokanlelogbon" },
      { id: 32, day: "" },
      { id: 33, day: "" },
      { id: 34, day: "" },
      { id: 35, day: "" },
      { id: 36, day: "" },
      { id: 37, day: "" },
      { id: 38, day: "" },
      { id: 39, day: "" },
      { id: 40, day: "" },
      { id: 41, day: "" },
      { id: 42, day: "" },
    ],
    english: [
      { id: 0, day: "Zero" },
      { id: 1, day: "One" },
      { id: 2, day: "Two" },
      { id: 3, day: "Three" },
      { id: 4, day: "Four" },
      { id: 5, day: "Five" },
      { id: 6, day: "Six" },
      { id: 7, day: "Seven" },
      { id: 8, day: "Eight" },
      { id: 9, day: "Nine" },
      { id: 10, day: "Ten" },
      { id: 11, day: "Eleven" },
      { id: 12, day: "Twelve" },
      { id: 13, day: "Thirteen" },
      { id: 14, day: "Fourteen" },
      { id: 15, day: "Fifteen" },
      { id: 16, day: "Sixteen" },
      { id: 17, day: "Seventeen" },
      { id: 18, day: "Eighteen" },
      { id: 19, day: "Nineteen" },
      { id: 20, day: "Twenty" },
      { id: 21, day: "Twenty One" },
      { id: 22, day: "Twenty two" },
      { id: 23, day: "Twenty Three" },
      { id: 24, day: "Twenty four" },
      { id: 25, day: "Twenty five" },
      { id: 26, day: "Twenty six" },
      { id: 27, day: "Twenty seven" },
      { id: 28, day: "Twenty eight" },
      { id: 29, day: "Twenty nine" },
      { id: 30, day: "Thirty" },
      { id: 31, day: "Thirty one" },
      { id: 32, day: "" },
      { id: 33, day: "" },
      { id: 34, day: "" },
      { id: 35, day: "" },
      { id: 36, day: "" },
      { id: 37, day: "" },
      { id: 38, day: "" },
      { id: 39, day: "" },
      { id: 40, day: "" },
      { id: 41, day: "" },
      { id: 42, day: "" },
    ],
  };

  return dateArrays;
}

export { calendarData };
