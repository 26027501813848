import React from "react";
import { Routes, Route } from "react-router-dom";
import Home from "./Home/Home";
import Contact from "./Contact/Contact";
import Policy from "./Policy/Policy";
import Footer from "../Footer/Footer";
import About from "./About/About";
const Public = () => {
  return (
    <React.Fragment>
      <section className="calendar-container">
        <Routes>
          <Route path="/" element={<Home />}></Route>
          <Route path="" element={<Home />}></Route>
          <Route path="/about-us" element={<About />}></Route>
          <Route path="/contact-us" element={<Contact />}></Route>
          <Route path="/policy" element={<Policy />}></Route>
        </Routes>

        <Footer />
      </section>
    </React.Fragment>
  );
};

export default Public;
