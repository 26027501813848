import React from "react";
import Calendar from "./Calendar";

const Home = () => {
  document.title = "Oguafo - Home";
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <React.Fragment>
      <Calendar />
    </React.Fragment>
  );
};

export default Home;
