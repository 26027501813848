import React from "react";
import { calendarData } from "../../data/calendar-data";
import FetchDate from "../../services/FetchDate";
import DateModal from "../../Modals/DateModal";
import dateProcess from "../../services/dateProcess";
import Header from "../../Header/Header";
import SearchDateModal from "../../Modals/SearchModal";
import IconButton from "@mui/material/IconButton";
import ArrowForwardIos from "@mui/icons-material/ArrowForwardIos";
import ArrowBackIosNew from "@mui/icons-material/ArrowBackIosNew";
import Tooltip from "@mui/material/Tooltip";
import { Adsense } from "@ctrl/react-adsense";
import Footer from "../../Footer/Footer";
import CalendarActions from "../../templates/CalendarActions";

interface Window {
  adsbygoogle: { [key: string]: unknown }[];
}

const Calendar = () => {
  const [calendar_data, setCalendar] = React.useState<any>({
    calendar_days: [],
    week_days: [],
    cur_date: "",
    cur_lang: "",
    last_month_last_day: "",
    next_month_first_day: "",
  });
  React.useEffect(() => {
    setCalendar(FetchDate(false, "igbo"));
    console.log("Window:: ", Window);
    //(window.adsbygoogle:Window = window.adsbygoogle || []).push({});
  }, []);

  console.log("cur_date:: ", calendar_data.cur_date);

  const jumpDate = React.useCallback(
    (dtx: any) => {
      console.log(dtx, "::");
      let dt_arr = dateProcess(dtx, calendar_data.cur_lang);
      launchDate(dt_arr);
      setCalendar(FetchDate(dtx, calendar_data.cur_lang));
      console.log(dt_arr, "::dt_arr");
      /**
       *  const goNext = (idate: string) => {
    setCalendar(FetchDate(idate, calendar_data.cur_lang));
       }
       */
    },
    [calendar_data]
  );

  const onModalClose = () => {
    setModal({ ...modal, onopen: false, loaded: false });
  };
  const onSearchClose = () => {
    setSearch({ ...search, onopen: false, loaded: false });
  };

  const [modal, setModal] = React.useState<any>({
    onopen: false,
    onclose: onModalClose,
    loaded: false,
  });
  const [search, setSearch] = React.useState<any>({
    onopen: false,
    onclose: onSearchClose,
    loaded: false,
  });

  const launchDate = (item: any) => {
    console.log(item, "::lnch");
    setModal({
      ...modal,
      onopen: true,
      loaded: true,
      cur_lang: calendar_data.cur_lang,
      ...item,
    });
  };

  const switchLang = (lang: string) => {
    setCalendar(FetchDate(calendar_data.cur_date, lang));
  };
  const goNext = (idate: string) => {
    console.log(idate, "::");
    setCalendar(FetchDate(idate, calendar_data.cur_lang));
  };

  const launchSearch = () => {
    setSearch({ ...search, onopen: true, loaded: true });
  };

  const ad_html = `<script async src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-6951734981044353"
     crossorigin="anonymous"></script>
<!-- Oguafo Horizontal -->

<script>
     (adsbygoogle = window.adsbygoogle || []).push({});
</script>`;

  return (
    <React.Fragment>
      <Header />

      <section className="container">
        <div className="curver">
          <div
            className="
          summary-cell flex 
          align-items-center "
          >
            <div className="spacer flex flex-row align-items-center">
              <span className="px0">
                <Tooltip title="Previous Month">
                  <a onClick={() => goNext(calendar_data.last_month_last_day)}>
                    <i className="fas fa-chevron-left"></i>
                  </a>
                </Tooltip>
              </span>
              <span className="spacer text-center px5">
                {calendar_data.cur_month_label}, {calendar_data.cur_year_label}
              </span>
              <span className="px0">
                <Tooltip title="Next Month">
                  <a onClick={() => goNext(calendar_data.next_month_first_day)}>
                    <i className="fas fa-chevron-right"></i>
                  </a>
                </Tooltip>
              </span>
            </div>
            <div className="spacer text-right">
              <CalendarActions
                doSwitch={switchLang}
                doSearch={launchSearch}
                cur_lang={calendar_data.cur_lang}
              />
            </div>
          </div>
          <div className="cells-container">
            <div className="week-bar">
              {calendar_data.week_days.map((item: any) => (
                <div
                  key={item.day}
                  className="
          week-cell flex 
          align-items-center 
          justify-content-center"
                >
                  <Tooltip title={item.day}>
                    <>
                      <span className="sm-show-inline-block">{item.short}</span>
                      <span className="sm-hide-inline-block">{item.day}</span>
                    </>
                  </Tooltip>
                </div>
              ))}
            </div>
            {calendar_data.calendar_days.map((item: any, index: number) => (
              <div className="week-bar" key={item + index}>
                {item.map((itm: any, indx: number) => (
                  <div
                    key={indx}
                    className={
                      itm.todays_class === "today_active"
                        ? "day-cell today_active"
                        : itm.disabled === "disabled"
                        ? "day-cell disabled"
                        : "day-cell"
                    }
                    onClick={() => launchDate(itm)}
                  >
                    <span className="day-span">{itm.todays_count}</span>
                    <span className="trad-span">{itm.trad_day}</span>
                  </div>
                ))}
              </div>
            ))}
          </div>
        </div>
      </section>
      {search.loaded && (
        <SearchDateModal
          onclose={search.onclose}
          onopen={search.onopen}
          fetch={jumpDate}
        />
      )}
      {modal.loaded && <DateModal data={modal} />}
    </React.Fragment>
  );
};

export default Calendar;
