import React from "react";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <React.Fragment>
      <footer>
        <div className="container flex flex-row flex-row-resp align-items-center">
          <span className="copyright">
            &copy; 2017 Cyprex Communications LTD
          </span>
          <span className="spacer"></span>
          <ul className="xd">
            <li>
              <Link to={`/policy`}>Privacy Policy</Link>
            </li>
            <li>|</li>
            <li>
              <Link to={`/contact-us`}>Contact Us</Link>
            </li>
          </ul>
        </div>
      </footer>
    </React.Fragment>
  );
};

export default Footer;
