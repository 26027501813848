import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Public from "./public/Public";
import Home from "./public/Home/Home";
import Policy from "./public/Policy/Policy";
import "./index.css";
import Header from "./Header/Header";
import Footer from "./Footer/Footer";
declare var adsbygoogle: any;
const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <Routes>
        <Route path="/*" element={<Public />}>
          <Route
            path="*"
            element={
              <>
                <section className="full-width full-height">
                  <Header />
                  <div className="minus-header ">
                    <h1>404!</h1>
                    <h2 className="text-center">
                      The page you are looking for does not exist
                    </h2>
                  </div>
                  <Footer />
                </section>
              </>
            }
          />
        </Route>
      </Routes>
    </BrowserRouter>
  </React.StrictMode>
);
