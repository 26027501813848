import { calendarData } from "../data/calendar-data";

const dateProcess = (tda: any, language: any) => {
  const dateArrays: any = calendarData();
  language = language ? language : "english";
  let pos_market_days = dateArrays.pos_market_days[language];
  let neg_market_days = dateArrays.neg_market_days[language];
  let ref_eke_day = dateArrays.ref_eke_day;
  //console.log(ref_eke_day)
  const months = dateArrays.naija_months;
  //console.log(months[language])
  const now = new Date(tda);
  const ref_date = ref_eke_day[language];
  const then_date = new Date(ref_date);
  //console.log('ref_date ::',then)
  //console.log('today_date ::',now)
  let daySeconds = 60 * 60 * 24 * 1000;
  let now_seconds = now.getTime();
  let now_date = now.getDate();
  let then_seconds = then_date.getTime();
  //console.log('now ::',now_seconds)
  //console.log('then ::',then_seconds)
  let diff_seconds = Math.ceil(now_seconds - then_seconds);
  let is_neg = diff_seconds < 0 ? true : false;
  let diff_days = is_neg
    ? (diff_seconds * -1) / daySeconds
    : diff_seconds / daySeconds;
  let dindex = diff_days % 4;

  let mar_day = is_neg ? neg_market_days[dindex] : pos_market_days[dindex];
  let result = {} as any;
  result.trad_day = mar_day;
  result.day_difference = diff_days;
  let thix = dateArrays.naija_days[language][now_date];
  let sxo = { ...{}, ...thix };
  result.todays_day = sxo.day;
  result.day_object = sxo;
  result.todays_day_num = now_date;
  result.modul = dindex;
  result.todays_unix = now_seconds;
  let tmt = now.getMonth();
  let tmy = now.getFullYear();
  let tmw = now.getDay();
  result.todays_month_offset = tmt;
  result.todays_year = tmy;
  result.week_object = dateArrays.naija_weekdays[language][tmw];
  result.month_object = dateArrays.naija_months[language][tmt];
  result.raw_date = now;
  //result.xday_era = (is_neg) ? 'past':'future';
  //console.log(result)
  return result;
}; //date_diff_now

export default dateProcess;
