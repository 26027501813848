import React from "react";
import Header from "../../Header/Header";
import { Link } from "react-router-dom";

const Contact = () => {
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <React.Fragment>
      <Header />
      <section>
        <div className="page-top">
          <div className="container">
            <h1>Contact Us</h1>
          </div>
        </div>
        <div className="container policy-content">
          <h2>Questions</h2>
          <p>
            If you have any questions about this project, please contact us at{" "}
            <strong>inquiry@cyprex.ng</strong> or by mail at:
          </p>
          <p>Cyprex Communcations LTD.</p>
          <p>B26 Ultimate Plaza,</p>
          <p>Abakaliki Road, Awka. Anambra State. Nigeria</p>
          <h3>Or contact Joseph Achebe directly at:</h3>
          <p>
            <strong>joe@cyprex.ng</strong>
          </p>
        </div>
      </section>
    </React.Fragment>
  );
};

export default Contact;
