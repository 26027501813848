import React from "react";
import { Link } from "react-router-dom";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import Button from "@mui/material/Button";
import Tooltip from "@mui/material/Tooltip";
import Menu from "@mui/material/Menu";
import Language from "@mui/icons-material/Language";
import { styled, useTheme } from "@mui/material/styles";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import KeyboardArrowDown from "@mui/icons-material/KeyboardArrowDown";
import MenuItem from "@mui/material/MenuItem";
import { CopyrightSharp, Search, Translate } from "@mui/icons-material";
import List from "@mui/material/List";
import Box from "@mui/material/Box";

const Header = (props: any) => {
  const { children, nav_menu } = props;
  const pages: any[] = [
    { path: "english", component: "English", icon: <Language /> },
    { path: "hausa", component: "Hausa", icon: <Language /> },
    { path: "yoruba", component: "Yoruba", icon: <Language /> },
    { path: "igbo", component: "Igbo", icon: <Language /> },
  ];
  const [anchorElUser, setAnchorElUser] = React.useState(null);

  const handleOpenUserMenu = (event: any) => {
    setAnchorElUser(event.currentTarget);
  };
  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };
  return (
    <React.Fragment>
      <div className="header-container">
        <span className="ham-logo">
          <Link to="/">
            <img src="/images/logo2.png" alt="Logo" />
          </Link>
        </span>
        <span className="spacer"></span>

        <div className="header-show">
          <ul className="header-menu">
            <li>
              <Link to={`/`}>Home</Link>
            </li>
            <li>
              <Link to={`/contact-us`}>Contact Us</Link>
            </li>
          </ul>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Header;
