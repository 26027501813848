import React from "react";
import Header from "../../Header/Header";
import { Link } from "react-router-dom";

const Policy = () => {
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <React.Fragment>
      <Header />
      <section>
        <div className="page-top">
          <div className="container">
            <h1>Privacy Policy</h1>
          </div>
        </div>
        <div className="container policy-content">
          <p>
            This Privacy Policy describes Joseph Achebe, Cyprex Communications
            ("Joseph", "Cyprex", "we", "us" or "our") practices for handling
            your information in connection with this website
            ("www.calendar.cyprex.ng") and our "Oguafo" mobile application (e.g.
            apps, mobile app) made available to you on this website and our
            mobile application (collectively, the "Services"). This Privacy
            Policy describes how we handle personal information while you are
            using our website and services.
          </p>
          <p>
            For clarity, this Privacy Policy only applies to the Website
            ("www.calendar.cyprex.ng") and the ("Oguafo") mobile application and
            does not apply to:
          </p>
          <ol>
            <li>
              pull requests, issues and any other interactions or features
              related to participation in open source projects on GitHub, which
              are governed by GitHub's terms and conditions; or
            </li>
          </ol>
          <h2>What Kinds of Information Do We Collect?</h2>
          <p>
            Our website and mobile application DO NOT request any information
            from users.
          </p>
          <h2>Cookies and Similar Technologies</h2>
          <p>
            Cookies are small pieces of text used to store information on web
            browsers. Cookies are used to store and receive identifiers and
            other information on computers, phones and other devices. We may use
            cookies and similar technologies, for such things as maintaining and
            monitoring the infrastructure of the Services, ensuring security
            protections, analyzing how our Services perform and other analytics
            purposes, and fulfilling other legitimate purposes as further
            described in this Privacy Policy. We also use analytics cookies to
            better understand how our Services are being used by tracking how
            you interact with the Services and where you click. We use cookies
            served by Google Analytics to collect limited data directly from
            end-user browsers to enable us to better understand your use of the
            Services, including making use of the demographics and interests
            reports services of Google Analytics. Further information on how
            Google collects and uses this data, including processing in the
            United States, can be found at
            https://www.google.com/policies/privacy/partners/. You can opt-out
            of all Google supported analytics within the Services by going to
            the following webpage: https://tools.google.com/dlpage/gaoptout.
            Learn more about how we use cookies by reading our Cookie Policy.
          </p>
          <h2>How Do We Use Information?</h2>
          <p>
            We will use the information described above for the purpose of
            operating and providing the Websites and our Services. Specifically,
            we will use the information for the following purposes:
          </p>
          <ul>
            <li>
              <strong>Provide, personalize and improve our Services.</strong> We
              may use third party cookies NONE of which are stored anywhere on
              our facilties to track our user base information for improved
              service rendering.
            </li>
          </ul>
          <h2>How We Share Information</h2>
          <p>
            Since we do not collect personal information, there will NOT be a
            scenario where we may need to share them in any way. However, usage
            information gathered by Google Analytics might be used either by us
            or them perform analysis on the Services.
          </p>
          <h2>Children's Information</h2>
          <p>
            We do not collect any personal information both of adults and
            children.
          </p>
          <h2>Third-Party Links</h2>
          <p>
            Our Websites may contain links to other sites that we do not own or
            operate (e.g. GitHub). Except as provided in this Privacy Policy, we
            do not provide your information to these third parties without your
            consent. The linked websites have separate and independent privacy
            statements, notices and terms of use, which we recommend you
            carefully review. We do not have any control over such websites, and
            therefore have no liability or responsibility for the linked
            websites' personal information practices.
          </p>
          <h2>Data Retention</h2> We do not collect and hence will NOT retain
          users personal information anywhere on any of our facilties.
          <h2>Questions</h2>
          <p>
            If you have any questions about this Privacy Policy or our
            practices, please contact us at <strong>inquiry@cyprex.ng</strong>{" "}
            or by mail at:
          </p>
          <p>Cyprex Communcations LTD.</p>
          <p>B26 Ultimate Plaza,</p>
          <p>Abakaliki Road, Awka. Anambra State. Nigeria</p>
          <h3>Or contact Joseph Achebe directly at:</h3>
          <p>
            <strong>joe@cyprex.ng</strong>
          </p>
        </div>
      </section>
    </React.Fragment>
  );
};

export default Policy;
