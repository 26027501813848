import React from "react";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import IconButton from "@mui/material/IconButton";
import Button from "@mui/material/Button";
import Slide from "@mui/material/Slide";
import Close from "@mui/icons-material/Close";

const TransitionUp = React.forwardRef(function Transition(
  props: any,
  ref: any
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const extr = (date: any) => {
  let dtx = new Date(date);
  let tmt = dtx.getMonth() + 1;
  let tmy = dtx.getFullYear();
  let tmd = dtx.getDay();

  return `${tmt}/${tmd}/${tmy}`;
};

export default function SearchDateModal(props: any) {
  const { onopen, onclose, fetch } = props;

  const handleClose = () => {
    onclose();
  };
  const tmx = new Date();

  const [dateVal, setValue] = React.useState<any>(tmx);
  const handleDateChange = (target: any) => {
    setValue(target);
  };

  const findDate = (date: any) => {
    console.log("toFet:: ", date);
    fetch(date);
  };
  return (
    <>
      <Dialog
        open={onopen}
        aria-labelledby={"Me"}
        id={"md-" + tmx}
        TransitionComponent={TransitionUp}
      >
        <div className="flex flex-row align-items-center px10">
          <DialogTitle id={"label100"} sx={{ p: "14px" }}>
            <i className="fas fa-search"></i> Search Date
          </DialogTitle>

          <span className="spacer"></span>
          <IconButton onClick={handleClose} color="warning">
            <Close />
          </IconButton>
        </div>
        <DialogContent sx={{ p: "0 ", m: "0" }}>
          <section className="modal-width">
            <div className="py0">
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <Stack spacing={3}>
                  <DatePicker
                    disableFuture={false}
                    label="Select Date"
                    openTo="year"
                    views={["year", "month", "day"]}
                    value={dateVal}
                    onChange={handleDateChange}
                    renderInput={(params) => <TextField {...params} />}
                  />
                </Stack>
              </LocalizationProvider>
            </div>
          </section>
          <div className="px20 pb20">
            <Button
              fullWidth
              color="primary"
              size="large"
              variant="contained"
              onClick={() => findDate(dateVal)}
            >
              FIND DATE
            </Button>
          </div>
        </DialogContent>
      </Dialog>
    </>
  );
}
