import React from "react";
import Header from "../../Header/Header";
import { Link } from "react-router-dom";

const About = () => {
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <React.Fragment>
      <Header />
      <section>
        <div className="page-top">
          <div className="container">
            <h1>About Us</h1>
          </div>
        </div>
        <div className="container policy-content">
          <p>
            This Privacy Policy describes Meta Platforms, Inc. ("Meta", "we",
            "us" or "our") practices for handling your information in connection
            with this website and our open source-related websites ("Websites")
            and any content, related documentation, information and services
            (e.g. apps, tutorials, tools to support the developer workflow,
            access to resources, etc.) made available to you on this website
            (collectively, the "Services"). This Privacy Policy describes the
            personal information we process to support our Services.
          </p>
          <p>
            For clarity, this Privacy Policy only applies to the Websites and
            does not apply to any:
          </p>
          <ol>
            <li>
              use of open source code, documentation or specifications made
              available on GitHub, which are governed by the terms of the
              applicable open source license;
            </li>
            <li>
              pull requests, issues and any other interactions or features
              related to participation in open source projects on GitHub, which
              are governed by GitHub's terms and conditions; or
            </li>
            <li>
              use of any other Meta website, service or product, which are
              governed by the terms and conditions applicable to those
              offerings.
            </li>
          </ol>

          <h2>What Kinds of Information Do We Collect?</h2>
          <p>
            When you interact with us through our Services, we may collect or
            receive the following types of information:
          </p>
          <p>
            <strong>Information you provide directly to us.</strong> For certain
            activities, we may collect the following types of information:
            <ul>
              <li>
                <strong>Contact information,</strong> such as name, email
                address and contact details; and
              </li>
              <li>
                <strong>Other information you provide to us,</strong> such as
                when you send us correspondence or otherwise participate on the
                Services
              </li>
            </ul>
          </p>
          <p>
            <strong>Information we collect automatically.</strong> Depending on
            the type of device you use and how you interact with us, we may also
            collect certain information automatically when you use our Services,
            such as:
          </p>
          <p></p>
          <p></p>
          <p></p>
          <p></p>
          <p></p>
          <p></p>
        </div>
      </section>
    </React.Fragment>
  );
};

export default About;
