import React from "react";
import { Link } from "react-router-dom";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import Button from "@mui/material/Button";
import Tooltip from "@mui/material/Tooltip";
import Menu from "@mui/material/Menu";
import Language from "@mui/icons-material/Language";
import { styled, useTheme } from "@mui/material/styles";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import KeyboardArrowDown from "@mui/icons-material/KeyboardArrowDown";
import MenuItem from "@mui/material/MenuItem";
import { CopyrightSharp, Search, Translate } from "@mui/icons-material";
import List from "@mui/material/List";
import Box from "@mui/material/Box";

const CalendarActions = (props: any) => {
  const { doSearch, doSwitch, children, nav_menu, cur_lang } = props;
  const pages: any[] = [
    { path: "english", component: "English", icon: <Language /> },
    { path: "hausa", component: "Hausa", icon: <Language /> },
    { path: "yoruba", component: "Yoruba", icon: <Language /> },
    { path: "igbo", component: "Igbo", icon: <Language /> },
  ];
  const [anchorElUser, setAnchorElUser] = React.useState(null);

  const handleOpenUserMenu = (event: any) => {
    setAnchorElUser(event.currentTarget);
  };
  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };
  return (
    <React.Fragment>
      <div className="inline-flex flex-row align-items-center">
        {doSearch && (
          <span className="pr0">
            <Tooltip title="Search Date">
              <a onClick={doSearch}>
                <i className="fas fa-search"></i>
              </a>
            </Tooltip>
          </span>
        )}
        <span className="px10 grayed">|</span>
        {cur_lang && (
          <div className="pr10">
            <Tooltip title="Switch Language">
              <a
                onClick={handleOpenUserMenu}
                className=" flex align-items-center"
              >
                <i className="fas fa-language pr5"></i>
                <span className="sm-hide-inlinex smix ucap">{cur_lang}</span>
                <i className="fas fa-chevron-down txt-sm pl5"></i>
              </a>
            </Tooltip>
            <Menu
              sx={{ m: "0", p: "0 !important" }}
              id="menu-appbar"
              anchorEl={anchorElUser}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "right",
              }}
              keepMounted
              PaperProps={{
                elevation: 0,
                sx: {
                  overflow: "visible",
                  filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                  mt: 1.5,
                  "& .MuiAvatar-root": {
                    width: 32,
                    height: 32,
                    ml: -0.5,
                    mr: 1,
                  },
                  "&:before": {
                    content: '""',
                    display: "block",
                    position: "absolute",
                    top: 0,
                    right: 14,
                    width: 10,
                    height: 10,
                    bgcolor: "background.paper",
                    transform: "translateY(-50%) rotate(45deg)",
                    zIndex: 0,
                  },
                },
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              open={Boolean(anchorElUser)}
              onClose={handleCloseUserMenu}
              onClick={handleCloseUserMenu}
            >
              <List sx={{ p: "0", m: "0" }}>
                {pages.map((item: any, index: number) => (
                  <MenuItem key={index} sx={{ p: "0", m: "0" }}>
                    <ListItem
                      disablePadding
                      button
                      key={item.date}
                      divider={true}
                    >
                      <ListItemButton onClick={() => doSwitch(item.path)}>
                        <ListItemIcon>{item.icon}</ListItemIcon>
                        <ListItemText>{item.component}</ListItemText>
                      </ListItemButton>
                    </ListItem>
                  </MenuItem>
                ))}
              </List>
              <Divider />
              <MenuItem>
                <List sx={{ m: "0", p: "0" }}>
                  <ListItem disablePadding>
                    <ListItemIcon>
                      <CopyrightSharp />
                    </ListItemIcon>
                    <ListItemText>Cyprex</ListItemText>
                  </ListItem>
                </List>
              </MenuItem>
              {/* </Paper> */}
            </Menu>
          </div>
        )}
      </div>
    </React.Fragment>
  );
};

export default CalendarActions;
